/* eslint-disable camelcase */
// @flow

import React, { useEffect, useState } from 'react'
import compose from 'recompose/compose'
import { connect as withRedux } from 'react-redux'
import Step from '@material-ui/core/Step'
import StepButton from '@material-ui/core/StepButton'
import StepLabel from '@material-ui/core/StepLabel'
import StepperUI from '@material-ui/core/Stepper'
import { withStyles } from '@material-ui/core/styles'
import CompleteIcon from '@material-ui/icons/Done'
import PreviousStepIcon from '@material-ui/icons/ChevronLeft'
import NextStepIcon from '@material-ui/icons/ChevronRight'
import ErrorIcon from '@material-ui/icons/Close'
import Button from '@autodisol/ads-js/components/CustomButton'
import cond from 'sharyn/util/cond'
import { useTranslation } from 'react-i18next'
import Alert from '@autodisol/ads-js/components/Alert'
import CloseModeFormButtons from 'quote/cmp/Stepper/CloseModeFormButtons'
import { MODES } from 'utils/constants/modes'
import { detailsBike } from 'quote/cmp/validationRules'
import { useMutation } from '@apollo/client'
import { createQuoteMutation } from 'quote/quote-queries'
import { elementIdentify, setCurrentDraftQuoteId } from '_client/redux/actions'
import { notify } from 'sharyn/redux/actions'
import { genericErrorNotification } from 'app/notifications'
import { IDENTIFICATION_VEHICLE_TYPE } from 'utils/constants'
import { successColor, errorColor, secondaryColorLight } from '@autodisol/ads-js/components/styles'

import type { Mode } from 'types/modes'
import type { IdentificationMode } from 'quote/types'

const DETAILS_STEP = 0
const PICTURE_STEP = 2
const NUMBER_OF_PICTURES_REQUIRED = {
  standard_bike: 6,
  standard: 4,
  resval: 4,
}

const getStepIconColor = ({
  isComplete,
  hasError,
  stepNumber,
  activeStep,
  isDisabled,
}: {
  isComplete: boolean,
  hasError: boolean,
  stepNumber: number,
  activeStep: number,
  isDisabled: boolean,
}) => {
  if (isDisabled) return secondaryColorLight

  if (isComplete && stepNumber !== activeStep) return successColor

  if (hasError && stepNumber !== activeStep) return errorColor

  if (!hasError && !isComplete && stepNumber !== activeStep) return '#414B56'

  return '#FFF'
}

const getLabelClassName = ({
  isComplete,
  hasError,
  stepNumber,
  activeStep,
  isDisabled,
}: {
  isComplete: boolean,
  hasError: boolean,
  stepNumber: number,
  activeStep: number,
  isDisabled: boolean,
}) => {
  if (isDisabled) return 'disabled'

  if (hasError && stepNumber !== activeStep) return 'error'

  if (isComplete && stepNumber !== activeStep) return 'completed'

  if (!isComplete && stepNumber !== activeStep) return 'default'

  return 'active'
}

const getStepLabelColor = ({
  isComplete,
  hasError,
  isDisabled,
}: {
  isComplete: boolean,
  hasError: boolean,
  isDisabled?: boolean,
}) => {
  if (isDisabled) return secondaryColorLight

  if (hasError) return errorColor

  if (isComplete) return successColor

  return undefined
}

const getCustomConnectorColor = ({ isValid, hasError }) => {
  if (hasError) return errorColor

  if (isValid) return successColor

  return undefined
}

const StepIcon = ({
  formValidator,
  icon: DefaultIcon,
  i,
  activeStep,
  isDisabled,
}: {
  formValidator: Function,
  icon: any,
  i: number,
  activeStep: number,
  isDisabled: boolean,
}) => {
  const color = getStepIconColor({
    isComplete: formValidator().steps[i].isComplete,
    hasError: Object.keys(formValidator().steps[i].errors).length > 0,
    stepNumber: i,
    activeStep,
    isDisabled: activeStep !== i && isDisabled,
  })

  return cond(
    [
      [
        Object.keys(formValidator().steps[i].errors).length > 0,
        () => <ErrorIcon style={{ color }} />,
      ],
      [
        formValidator().steps[i].isComplete,
        () => (
          <CompleteIcon
            style={{
              color,
            }}
          />
        ),
      ],
    ],
    () => <DefaultIcon style={{ color }} />,
  )
}
const CustomConnector = ({ className, style }: { className?: string, style?: Object }) => (
  <div {...{ className, style }} />
)

const getQuoteInputForCreateQuote = ({ fields, mode, vin, plate }: any) => ({
  category: mode,
  plate: plate ?? fields.plate,
  vin: vin ?? fields.vin,
  make: fields.make,
  type: fields.type ?? IDENTIFICATION_VEHICLE_TYPE.MOTO,
  model: fields.model,
  version: fields.version || null,
  energy: fields.energy,
  hpPower: Number(fields.hpPower),
  kwPower: Number(fields.kwPower),
  displacement: Number(fields.displacement),
  gearbox: fields.gearbox,
  co2: fields.co2 !== null ? Number(fields.co2) : null,
  color: fields.color,
  productionCountry: 'FR',
  body: fields.bodywork,
  seats: Number(fields.seats),
  catalogSource: 'autovista',
  regType: fields.regType,
  releaseDate: fields.firstRegDate && {
    date: fields.firstRegDate,
    timezone: 'UTC',
  },
  lastRegDate: fields.lastRegDate && {
    date: fields.lastRegDate,
    timezone: 'UTC',
  },
  taxPower: fields.taxPower && Number(fields.taxPower),
  costs: {},
})

const StepperJSX = ({
  classes: css,
  activeStep,
  setActiveStep,
  steps,
  formValidator,
  isSavingQuote,
  isSendingQuote,
  isEditionMode,
  isCorrectionMode,
  handleSave,
  identifiedVehicle,
  isEstimateLoading,
  handleAutomaticSubmit,
  rights,
  selectedVersion,
  furtherStepsDisabled,
  hasRequiredPhotos,
  numberPhotosAdded,
  isUpdateQuote,
  isCloseMode,
  isLoadingCloseCaseQuote,
  mode,
  field,
  dispatch,
  hasAlreadyBeenCreatedQuote,
  fields,
  identificationMode,
  ...rest
}: {
  classes: Object,
  activeStep: number,
  setActiveStep: Function,
  steps: Object[],
  formValidator: () => Object,
  isSavingQuote?: boolean,
  isSendingQuote?: boolean,
  isEditionMode?: boolean,
  isCorrectionMode?: boolean,
  handleSave: Function,
  identifiedVehicle?: Object,
  isEstimateLoading?: boolean,
  handleAutomaticSubmit: Function,
  rights: string[],
  selectedVersion?: Object,
  furtherStepsDisabled?: boolean,
  hasRequiredPhotos: boolean,
  numberPhotosAdded: number,
  isUpdateQuote: boolean,
  isCloseMode?: boolean,
  isLoadingCloseCaseQuote?: boolean,
  mode: Mode,
  field: Function,
  dispatch: Function,
  hasAlreadyBeenCreatedQuote: boolean,
  fields: Object,
  identificationMode: IdentificationMode,
}) => {
  const { t } = useTranslation()
  const isLastStep = activeStep === steps.length - 1
  const isValidForm = formValidator().isValid

  const [alertOptions, setAlertOptions] = useState({ isOpened: false })
  const [mustScrollToTheTop, setMustScrollToTheTop] = useState(false)

  const [createQuote] = useMutation(createQuoteMutation, {
    onCompleted: ({ create_quote }) => {
      dispatch(setCurrentDraftQuoteId(create_quote.id))

      if (create_quote) {
        dispatch(
          elementIdentify({
            id: create_quote?.id,
            furtherStepsDisabled: create_quote && false,
            hasAlreadyBeenCreatedQuote: true,
          }),
        )
      } else {
        dispatch(notify(genericErrorNotification))
      }
    },
  })

  useEffect(() => {
    if (mustScrollToTheTop) {
      window.scrollTo(0, 0)

      setMustScrollToTheTop(false)
    }
  }, [mustScrollToTheTop])

  const handleAlertOpening = options => {
    setAlertOptions({ ...options, isOpened: true })
  }

  const handleChangeStep = stepNumber => () => {
    if (
      activeStep === DETAILS_STEP &&
      !isEditionMode &&
      !isCorrectionMode &&
      !hasAlreadyBeenCreatedQuote &&
      mode === MODES.standard_bike
    ) {
      createQuote({
        variables: {
          quoteInput: getQuoteInputForCreateQuote({
            fields,
            mode,
            plate: identifiedVehicle?.plate,
            vin: identifiedVehicle?.vin,
          }),
        },
      })
    }

    if (
      activeStep === PICTURE_STEP &&
      numberPhotosAdded === NUMBER_OF_PICTURES_REQUIRED[mode] &&
      hasRequiredPhotos
    ) {
      handleAlertOpening({
        title: t('quote.identification.confirmModalAddPhoto.title'),
        content: (
          <div>
            {t('quote.identification.confirmModalAddPhoto.content', {
              numberOfPhotos: NUMBER_OF_PICTURES_REQUIRED[mode],
            })}
            <br />
            <span style={{ fontWeight: 700 }}>
              {t('quote.identification.confirmModalAddPhoto.question')}
            </span>
          </div>
        ),
        handlePositiveClose: () => {
          setActiveStep(stepNumber)
          setMustScrollToTheTop(true)

          setAlertOptions(prevState => ({ ...prevState, isOpened: false }))
        },
        handleNegativeClose: () => {
          setAlertOptions(prevState => ({ ...prevState, isOpened: false }))
        },
      })

      return
    }

    setActiveStep(stepNumber)
    window.scrollTo(0, 0)
  }

  const isCompletedDetailsStepStandardBike = detailsBike(identificationMode).requiredFields.every(
    element => Boolean(field(element)),
  )
  const isStandardBikeMode = mode === MODES.standard_bike

  return (
    <>
      <div {...rest}>
        <StepperUI alternativeLabel nonLinear className={css.stepper}>
          {steps.map(({ label, icon }, i) => {
            const prevStepIndex = i - 1
            const hasError = Object.keys(formValidator().steps[i].errors).length > 0
            const isComplete = formValidator().steps[i]?.isComplete
            const isDisabled =
              furtherStepsDisabled ||
              (furtherStepsDisabled === undefined && !isEditionMode) ||
              isUpdateQuote
            const stepLabelClassName = getLabelClassName({
              isComplete,
              hasError,
              stepNumber: i,
              activeStep,
              isDisabled: activeStep !== i && isDisabled,
            })

            return (
              <Step
                key={label}
                active={activeStep === i}
                className={css.step}
                connector={
                  <CustomConnector
                    className={
                      (furtherStepsDisabled ||
                        furtherStepsDisabled === undefined ||
                        isUpdateQuote) &&
                      !isEditionMode
                        ? css.disabledConnector
                        : css.connector
                    }
                    style={{
                      background:
                        prevStepIndex >= 0 &&
                        getCustomConnectorColor({
                          isValid: formValidator().steps[prevStepIndex]?.isComplete,
                          hasError:
                            Object.keys(formValidator().steps[prevStepIndex]?.errors).length > 0,
                        }),
                    }}
                  />
                }
                disabled={isDisabled}
              >
                <StepButton
                  onClick={handleChangeStep(i)}
                  icon={<StepIcon {...{ formValidator, icon, i, activeStep, isDisabled }} />}
                  alternativeLabel
                  style={{ zIndex: 2 }}
                >
                  <StepLabel
                    classes={{
                      root: activeStep === i ? css.activeStepLabel : css.stepLabel,
                      labelContainer: css.stepLabelContainer,
                      label: css[stepLabelClassName],
                      active: css.stepActive,
                      disabled: activeStep === i ? css.activeStepLabel : css.disabledStepLabel,
                    }}
                    style={{
                      background: getStepLabelColor({
                        isComplete: activeStep === i && isComplete,
                        hasError: activeStep === i && hasError,
                      }),
                      borderColor: getStepLabelColor({
                        isComplete,
                        hasError,
                        isDisabled: activeStep !== i && isDisabled,
                      }),
                    }}
                    error={hasError}
                  >
                    {label}
                  </StepLabel>
                </StepButton>
              </Step>
            )
          })}
        </StepperUI>
        <div className={css.content}>{steps[activeStep].content}</div>
        <div className={css.submitContainer}>
          {!isCloseMode && (
            <>
              {(identifiedVehicle?.status === 'draft' ||
                !identifiedVehicle ||
                isCorrectionMode) && (
                <div className={css.submitButtons}>
                  <Button
                    variant="contained"
                    colorType="primary"
                    height="tall"
                    className={css.navButton}
                    onClick={handleChangeStep(activeStep === 0 ? steps.length - 1 : activeStep - 1)}
                    disabled={
                      (!isCompletedDetailsStepStandardBike && isStandardBikeMode) ||
                      (furtherStepsDisabled && !isStandardBikeMode) ||
                      (furtherStepsDisabled === undefined && !isEditionMode && !isStandardBikeMode)
                    }
                  >
                    <PreviousStepIcon />
                  </Button>
                  {!formValidator().isValid ? (
                    <Button
                      variant="contained"
                      colorType="primary"
                      height="tall"
                      className={css.submitButton}
                      disabled={
                        (!isCompletedDetailsStepStandardBike && isStandardBikeMode) ||
                        (furtherStepsDisabled && !isStandardBikeMode) ||
                        (furtherStepsDisabled === undefined &&
                          !isEditionMode &&
                          !isStandardBikeMode)
                      }
                      onClick={handleChangeStep((activeStep + 1) % steps.length)}
                    >
                      {t('quote.nextStep')}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      colorType="primary"
                      type="submit"
                      height="tall"
                      fullWidth
                      disabled={isEstimateLoading || isSavingQuote}
                      className={css.submitButton}
                      isLoading={isSendingQuote}
                    >
                      {isCorrectionMode
                        ? t('quote.identification.resendQuote')
                        : t('quote.identification.sendQuote')}
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    colorType="primary"
                    height="tall"
                    className={css.navButton}
                    disabled={
                      (!isCompletedDetailsStepStandardBike && isStandardBikeMode) ||
                      (furtherStepsDisabled && !isStandardBikeMode) ||
                      (furtherStepsDisabled === undefined &&
                        !isEditionMode &&
                        !isStandardBikeMode) ||
                      isUpdateQuote
                    }
                    onClick={handleChangeStep((activeStep + 1) % steps.length)}
                  >
                    <NextStepIcon />
                  </Button>
                </div>
              )}
              <div className={css.submitButtons} style={{ display: 'block' }}>
                {!isCorrectionMode &&
                  rights.includes('autoestimate_quote') &&
                  mode === MODES.standard && (
                    <Button
                      variant="contained"
                      fullWidth
                      colorType="secondary"
                      height="short"
                      disabled={
                        isSendingQuote || !formValidator().isValid || isSavingQuote || isUpdateQuote
                      }
                      className={css.submitButton}
                      isLoading={isEstimateLoading}
                      onClick={handleAutomaticSubmit}
                      style={{ margin: '0px 0px 20px 0px' }}
                    >
                      {t('quote.getOnlyAdvisedPrices')}
                    </Button>
                  )}
                <Button
                  variant="contained"
                  colorType="secondary"
                  height="short"
                  fullWidth
                  className={css.submitButton}
                  disabled={
                    !formValidator().steps[0]?.isComplete ||
                    formValidator().hasError ||
                    isSendingQuote ||
                    isEstimateLoading ||
                    furtherStepsDisabled ||
                    (furtherStepsDisabled === undefined && !isEditionMode) ||
                    isUpdateQuote
                  }
                  isLoading={isSavingQuote}
                  onClick={handleSave}
                  style={{ margin: 0 }}
                >
                  {t('quote.save')}
                </Button>
              </div>
            </>
          )}

          {isCloseMode && (
            <CloseModeFormButtons
              isDisabledCloseButton={(isLastStep && !isValidForm) || isLoadingCloseCaseQuote}
              isCloseButton={isLastStep || isValidForm}
              handleChangeStep={
                !isValidForm ? handleChangeStep((activeStep + 1) % steps.length) : undefined
              }
              isLoading={isLoadingCloseCaseQuote}
            />
          )}
        </div>
      </div>

      <Alert {...alertOptions} />
    </>
  )
}

const Stepper: any = compose(
  withStyles(theme => ({
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        paddingTop: 80,
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: 150,
      },
    },
    stepper: {
      position: 'fixed',
      top: 123,
      left: 0,
      width: '100%',
      zIndex: 1101,
      background: 'white',
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14)',
      justifyContent: 'center',
      transition: 'transform 0.2s ease-in-out',
      [theme.breakpoints.up('sm')]: {
        top: 64,
      },
    },
    stepText: {
      color: '#414B56 !important',
      marginTop: '0 !important',
      fontWeight: '700 !important',
    },
    active: {
      extend: 'stepText',
      color: '#FFF !important',
    },
    completed: {
      extend: 'stepText',
      color: `${theme.palette.success.main} !important`,
    },
    error: {
      extend: 'stepText',
      color: `${theme.palette.error.main} !important`,
    },
    default: {
      extend: 'stepText',
      color: '#414B56 !important',
    },
    disabled: {
      extend: 'stepText',
      color: `${theme.palette.secondary.light} !important`,
    },
    stepTextValid: {
      extends: 'stepText',
      color: `${theme.palette.success.main} !important`,
    },
    stepLabelContainer: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    stepLabel: {
      background: '#FFF',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: `3px solid #414B56`,
      boxShadow: 'inset 0 0 0 2px white',
      [theme.breakpoints.down('sm')]: {
        width: 40,
        height: 40,
        borderRadius: '50%',
      },
      [theme.breakpoints.up('md')]: {
        width: 110,
        height: 65,
        borderRadius: 8,
      },
    },
    activeStepLabel: {
      extend: 'stepLabel',
      background: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    disabledStepLabel: {
      background: '#FFF',
    },
    connector: {
      left: 'calc(-50% + 10px)',
      right: 'calc(50% + 10px)',
      position: 'absolute',
      background: theme.palette.secondary.dark,
      [theme.breakpoints.down('sm')]: {
        height: 4,
        top: 18,
      },
      [theme.breakpoints.up('md')]: {
        height: 8,
        top: 26,
      },
    },
    disabledConnector: {
      left: 'calc(-50% + 10px)',
      right: 'calc(50% + 10px)',
      position: 'absolute',
      background: theme.palette.secondary.light,
      [theme.breakpoints.down('sm')]: {
        height: 4,
        top: 18,
      },
      [theme.breakpoints.up('md')]: {
        height: 8,
        top: 26,
      },
    },
    step: {
      [theme.breakpoints.up('md')]: {
        flex: 0,
      },
    },
    submitContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 30,
    },
    submitButtons: {
      width: '100%',
      marginTop: 20,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      [theme.breakpoints.up('md')]: {
        maxWidth: 350,
      },
    },
    submitButton: {
      flexGrow: 1,
      margin: '0 10px',
    },
    navButton: {
      width: 50,
    },
  })),
  withRedux(({ user }) => ({
    hasAlreadyBeenCreatedQuote: user.data.elements?.hasAlreadyBeenCreatedQuote ?? false,
  })),
)(StepperJSX)

export default Stepper
